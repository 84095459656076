import { SingleChoice } from "components/inquiry-answers/single-choice";
import { append, remove } from "lib/collections";

class MultipleChoice extends SingleChoice {
  onChoiceSelected(e) {
    const el = e.target;
    const index = parseInt(el.dataset.index);
    let selection = null;

    if (this.state.selected.includes(index)) {
      selection = remove(this.state.selected, index);
    } else {
      selection = append(this.state.selected, index);
    }

    if (el.nodeName !== "BUTTON") return;

    this.setState({ selected: selection });
    this.store();
  }
}

MultipleChoice.define("ni-multiple-choice-answer");
export { MultipleChoice };
