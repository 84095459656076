import HyperHTMLElement from "hyperhtml-element";
import { debounce } from "lodash-es";
import { get } from "lib/requests";

const wire = HyperHTMLElement.wire;

class AutoCompleteWidget extends HyperHTMLElement {
  static get observedAttributes() {
    return ["endpoint", "placeholder", "id", "trigger"];
  }

  get defaultState() {
    return {
      entries: [],
      selection: false,
    };
  }

  get hasResults() {
    return this.state.entries.length > 0;
  }

  get isSelected() {
    return this.state.selection;
  }

  created() {
    this.debouncedSearch = debounce(this.search, 300);
    document.addEventListener("keydown", this.onKeyDown.bind(this));
    this.render();
    this.removeAttribute("id");
  }

  onKeyDown(e) {
    if (e.which === 27) {
      this.setState({ entries: [] });
    }
  }

  onInput(e) {
    const value = e.target.value;
    if (value === "") {
      return;
    }
    this.debouncedSearch(value);
  }

  onSelect(e) {
    const index = parseInt(e.target.closest("li").dataset.index);
    this.setState({ selection: this.state.entries[index] });

    if (this.trigger) {
      this.dispatchEvent(
        new CustomEvent("ni-autocomplete-selected", {
          bubbles: true,
          detail: this.state.selection,
        })
      );

      this.onSelectionClose();
    }
  }

  onSelectionClose() {
    this.refs.input.value = "";
    this.setState({ selection: false, entries: [] });
    this.refs.input.focus();
  }

  search(payload) {
    get(`${this.endpoint}?q=${encodeURIComponent(payload)}`).then((result) => {
      this.setState({ entries: result });
    });
  }

  render() {
    this.html`
      <div class="relative">
        <input
          type="text"
          name="input-widget"
          id="${this.id}"
          placeholder="${this.placeholder}"
          class="border px-3 py-2 w-full"
          hidden=${this.isSelected}
          onInput=${this}
          data-call="onInput"
          ref="input">
          <ul
            class="border-t border-l border-r absolute bg-white "
            hidden=${!this.hasResults || this.isSelected}
            onClick=${this}
            data-call="onSelect">
            ${this.state.entries.map(
              (entry, index) =>
                wire(entry, ":id")`
                  <li
                    class="p-2 border-b cursor-pointer hover:bg-gray-50"
                    data-index="${index}">
                    <strong>${entry.title}</strong><br>
                    <span class="text-sm">${entry.type}<span>
                  </li>
                `
            )}
          </ul>
          <div class="" hidden=${!this.isSelected}>
          <div class="flex">
            <div class="flex-1">
            <strong>${this.state.selection.title}</strong><br>
            <span class="font-sm">${this.state.selection.type}<span>
            </div>
            <button
              class="self-center text-right inline-block"
              onClick=${this}
              data-call="onSelectionClose">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    `;
  }
}

AutoCompleteWidget.define("ni-auto-complete");
export { AutoCompleteWidget };
