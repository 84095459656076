/**
 * Append an element to the given array.
 * Does not mutate the original array.
 *
 * @param {*[]} original Source array.
 * @param {*} element Element to append.
 * @returns {*[]} Copy of the source array after appending the element.
 */
function append(original, element) {
  return [...original, element];
}

/**
 * Capitalize text.
 *
 * @param {string} text Text to capitalize.
 * @returns {string} Capitalized string
 */
function capitalize(text) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

/**
 * Insert an element into the given array after the given position.
 * Does not mutate the original array.
 *
 * @param {*[]} original Source array.
 * @param {number} index Item will be inserted after the specified index.
 * @param {*} item Item to insert into the array.
 * @returns {*[]} Copy of the source array after insertion.
 */
function insertAfter(original, index, item) {
  const copy = [...original];

  if (original.length === 0) {
    index = 0;
  } else if (index >= original.length) {
    index = original.length;
  }

  copy.splice(index + 1, 0, item);
  return copy;
}

/**
 * Remove an element from the array.
 * Does not mutate the original array.
 *
 * @param {*[]} original Source array.
 * @param {*} element Element to remove.
 * @returns {*[]} Copy of the source array after element removal.
 */
function remove(original, element) {
  const copy = [...original];
  const index = copy.indexOf(element);
  copy.splice(index, 1);

  return copy;
}

/**
 * Remove an element from the array at a given position.
 * Does not mutate the original array.
 *
 * @param {*[]} original Source array.
 * @param {number} index Array index to remove.
 * @returns {*[]} Copy of the source array after index removal
 */
function removeAt(original, index) {
  const copy = [...original];
  copy.splice(index, 1);
  return copy;
}

export { append, capitalize, insertAfter, remove, removeAt };
