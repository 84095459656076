import HyperHTMLElement from "hyperhtml-element";
import { debounce } from "lodash-es";
import { del, post, put } from "lib/requests";

class QuestionWidget extends HyperHTMLElement {
  constructor() {
    super();
    this.debounce = debounce(this.store, 500);
  }

  get endpoint() {
    return this.getAttribute("endpoint");
  }

  get questionEndpoint() {
    return `${this.endpoint}/${this.state.id}`;
  }

  onFormChange(e) {
    const el = e.target;
    const data = {};

    data[el.name] = el.value;
    this.setState(data);
    this.debounce();
  }

  onClose(e) {
    const event = new CustomEvent(`ni-widget:${e.type}`, {
      bubbles: true,
      detail: this,
    });

    del(this.questionEndpoint)
      .then(() => this.dispatchEvent(event))
      .catch((error) => console.log(error));
  }

  store() {
    if (this.state.label === "") return;

    if (this.state.id === 0) {
      this.create();
    } else {
      this.update();
    }
  }

  create() {
    post(this.endpoint, this.state)
      .then((e) => this.setState({ id: e.id }))
      .catch((e) => console.log(e));
  }

  update() {
    put(this.questionEndpoint, this.state)
      .then((e) => this.setState({ id: e.id }))
      .catch((e) => console.log(e));
  }
}

export default QuestionWidget;
