import { SingleChoice } from "components/form-builder/single-choice-new";

class MultipleChoice extends SingleChoice {
  get defaultState() {
    return {
      id: 0,
      vm: 1,
      vp: 1,
      label: "",
      options: [],
      widget: "ni-multiple-choice",
    };
  }

  get title() {
    return "Multiple Choice";
  }
}

MultipleChoice.define("ni-multiple-choice");
export { MultipleChoice };
