import Quill from "quill/dist/quill";
import "quill/dist/quill.snow.css";
import ImageUploader from "quill-image-uploader";
import HyperHTMLElement from "hyperhtml-element";
import { debounce } from "lodash-es";
import { get, post, put } from "lib/requests";

Quill.register("modules/imageUploader", ImageUploader);

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["link", "image", "video"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];

const options = {
  // debug: "info",
  modules: {
    toolbar: toolbarOptions,
    imageUploader: {
      upload: (file) => {
        const form = new FormData();
        form.append("files", file);
        return post("/api/upload", form);
      },
    },
  },
  readOnly: false,
  theme: "snow",
};

class Editor extends HyperHTMLElement {
  static get observedAttributes() {
    return ["textEndpoint"];
  }

  created() {
    this.debounce = debounce(this.store, 500);
    this.classList.add("editor");
    this.editor = new Quill(".editor", options);
    this.form = this.closest("form");

    if (!this.hasAttribute("local")) {
      get(this.textEndpoint).then((response) => {
        this.editor.setContents(JSON.parse(response.body));
        this.editor.on("text-change", this.onTextChange.bind(this));
      });
    } else {
      this.editor.on("text-change", this.onTextChange.bind(this));
    }
  }

  onTextChange() {
    this.debounce();
  }

  store() {
    if (this.form.elements.namedItem("raw")) {
      this.form.raw.value = JSON.stringify(this.editor.getContents());
    }

    if (this.form.elements.namedItem("rendered")) {
      this.form.rendered.value = this.editor.root.innerHTML;
    }

    if (!this.hasAttribute("local")) {
      put(this.textEndpoint, {
        raw: JSON.stringify(this.editor.getContents()),
        rendered: this.editor.root.innerHTML,
      });
    }
  }
}

Editor.define("ni-editor");
