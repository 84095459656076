import HyperHTMLElement from "hyperhtml-element";
import { capitalize } from "lib/collections";

class Dropdown extends HyperHTMLElement {
  static get booleanAttributes() {
    return ["trigger"];
  }

  created() {
    const parent = this.parentElement;

    this.setAttribute("tabindex", "-1");
    this.setAttribute("role", "menu");

    parent.addEventListener("click", this);
    parent.addEventListener("keydown", this);
    parent.addEventListener("focusin", this);
    parent.addEventListener("focusout", this);
  }

  disconnectedCallback() {
    const parent = this.parentElement;
    parent.removeEventListener("click", this);
    parent.removeEventListener("keydown", this);
    parent.removeEventListener("focusout", this);
    parent.removeEventListener("focusin", this);
  }

  handleEvent(e) {
    this[`on${capitalize(e.type)}`](e);
  }

  onFocusin() {
    if ("cancel" in this.dataset) {
      clearTimeout(parseInt(this.dataset.cancel, 10));
    }
  }

  onFocusout() {
    this.dataset.cancel = setTimeout(() => {
      this.parentElement.removeAttribute("open");
    }, 0).toString();

    this.scrollIntoView();
  }

  onKeydown(e) {
    if (e.key === "Escape") {
      e.target.parentElement.removeAttribute("open");
    }
  }

  onClick(e) {
    const target = e.target.closest("[is=ni-menuitem]");

    if (this.parentElement.getAttribute("open") === null) {
      setTimeout(() => this.scrollIntoView(), 0);
    }

    if (!target) {
      return;
    }

    if (this.trigger) {
      const event = new CustomEvent(
        `${this.nodeName.toLowerCase()}:${e.type}`,
        {
          bubbles: true,
          detail: { payload: target.params },
        }
      );
      this.dispatchEvent(event);
      e.preventDefault();
    }
  }
}

class MenuItem extends HyperHTMLElement {
  static get observedAttributes() {
    return ["params"];
  }

  created() {
    this.setAttribute("role", "menuitem");
  }
}

MenuItem.define("ni-menuitem", { extends: "a" });
Dropdown.define("ni-dropdown");

export { Dropdown, MenuItem };
