import HyperHTMLElement from "hyperhtml-element";
import { post, put } from "lib/requests";

class BaseWidget extends HyperHTMLElement {
  static get observedAttributes() {
    return ["endpoint"];
  }

  get answerEndpoint() {
    return `${this.endpoint}/${this.state.id}`;
  }

  get defaultState() {
    return {
      id: 0,
      questionId: 0,
      inquiryId: 0,
      label: "",
      options: [],
      selected: [],
      readonly: false,
    };
  }

  store() {
    if (this.state.readonly) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent("ni-inquiry-answers-widget:wait", {
        bubbles: true,
      })
    );

    this.state.id === 0 ? this.create() : this.update();
  }

  create() {
    post(this.endpoint, this.state)
      .then((e) => {
        this.dispatchEvent(
          new CustomEvent("ni-inquiry-answers-widget:changed", {
            bubbles: true,
            detail: e,
          })
        );
        this.setState({ id: e.id });
      })
      .catch((e) => console.log(e));
  }

  update() {
    put(this.answerEndpoint, this.state)
      .then((e) => {
        this.dispatchEvent(
          new CustomEvent("ni-inquiry-answers-widget:changed", {
            bubbles: true,
            detail: e,
          })
        );
        this.setState({ id: e.id });
      })
      .catch((e) => console.log(e));
  }
}

export { BaseWidget };
