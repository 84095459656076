import { BaseWidget } from "components/inquiry-answers/base-widget";
import { debounce } from "lodash-es";

class FreeAnswer extends BaseWidget {
  created() {
    this.debounce = debounce(this.store, 500);
  }

  onInput(e) {
    this.setState({ selected: [e.target.value] });
    this.debounce();
  }

  render() {
    this.html`
      <h2 class="mb-2">${this.state.label}</h2>
      <textarea
        class="border border-gray-400 p-2 w-full"
        rows="10"
        oninput="${this}"
        data-call="onInput">${this.state.selected}</textarea>
    `;
  }
}

FreeAnswer.define("ni-free-answer-answer");
export { FreeAnswer };
