import { BaseWidget } from "components/inquiry-answers/base-widget";
import { wire } from "hyperhtml/esm";
import { post, put } from "lib/requests";

class SingleChoice extends BaseWidget {
  static get observedAttributes() {
    return ["endpoint"];
  }

  get answerEndpoint() {
    return `${this.endpoint}/${this.state.id}`;
  }

  get defaultState() {
    return {
      id: 0,
      questionId: 0,
      inquiryId: 0,
      label: "",
      options: [],
      selected: [],
    };
  }

  created() {
    this.addEventListener("click", this.onChoiceSelected);
  }

  onChoiceSelected(e) {
    const el = e.target;
    if (el.nodeName !== "BUTTON") return;

    this.setState({ selected: [parseInt(el.dataset.index)] });
    this.store();
  }

  isSelected(index) {
    return this.state.selected.includes(index);
  }

  store() {
    if (this.state.readonly) {
      return;
    }

    this.dispatchEvent(
      new CustomEvent("ni-inquiry-answers-widget:wait", {
        bubbles: true,
      })
    );

    this.state.id === 0 ? this.create() : this.update();
  }

  create() {
    this.dispatchEvent(
      new CustomEvent("ni-inquiry-answers-widget:wait", {
        bubbles: true,
      })
    );
    post(this.endpoint, this.state)
      .then((e) => {
        this.dispatchEvent(
          new CustomEvent("ni-inquiry-answers-widget:changed", {
            bubbles: true,
            detail: e,
          })
        );
        this.setState({ id: e.id });
      })
      .catch((e) => console.log(e));
  }

  update() {
    put(this.answerEndpoint, this.state)
      .then((e) => {
        this.dispatchEvent(
          new CustomEvent("ni-inquiry-answers-widget:changed", {
            bubbles: true,
            detail: e,
          })
        );
        this.setState({ id: e.id });
      })
      .catch((e) => console.log(e));
  }

  render() {
    this.html`
      <h2 class="mb-2 text-center">${this.state.label}</h2>
      <div class="text-center mt-6">
      ${this.state.options.map(this.renderOption, this)}
      </div>
    `;
  }

  renderOption(option) {
    return wire()`
      <button
       class="answer-quizz ${this.isSelected(option.weight) ? "selected" : ""}"
       data-index="${option.weight}">
        ${option.label}
      </button>
    `;
  }
}

if (customElements.get("ni-single.choice-answer") === undefined) {
  SingleChoice.define("ni-single-choice-answer");
}
export { SingleChoice };
