import HyperHTMLElement from "hyperhtml-element";
import { get, post } from "lib/requests";
import { FreeAnswer } from "components/inquiry-answers/free-answer";
import { MultipleChoice } from "components/inquiry-answers/multiple-choice";
import { SingleChoice } from "components/inquiry-answers/single-choice";
import { Quiz } from "components/inquiry-answers/quiz";

const WIDGETS = {
  "ni-free-answer": FreeAnswer,
  "ni-multiple-choice": MultipleChoice,
  "ni-single-choice": SingleChoice,
  "ni-single-choice-inquiry": SingleChoice,
  "ni-quiz": Quiz,
};

class InquiryAnswerBuilder extends HyperHTMLElement {
  static get observedAttributes() {
    return ["endpoint", "readonly", "submit"];
  }

  get defaultState() {
    return {
      inquiry: [],
      questions: [],
      answers: [],
      index: 0,
    };
  }

  get empty() {
    return this.state.questions.length === 0;
  }

  get isReadOnly() {
    return this.readonly === "";
  }

  get isEverythingAnswered() {
    if (this.state.questions.length > this.state.answers.length) {
      return false;
    }

    if (this.state.questions.length === this.state.answers.length) {
      return true;
    }
  }

  created() {
    this.moveOn = true;

    get(this.endpoint)
      .then((inquiry) => {
        this.setState({
          inquiry: inquiry.inquiry,
          answers: inquiry.answers,
          questions: inquiry.questions,
        });
        this.toggleSubmitButton();
      })
      .catch((error) => console.log(error));

    document.addEventListener("keydown", (e) => {
      switch (e.which) {
        case 37:
          this.previous();
          break;
        case 39:
          this.next();
          break;
      }
    });

    this.addEventListener("ni-inquiry-answers-widget:wait", () => {
      this.moveOn = false;
    });

    this.addEventListener("ni-inquiry-answers-widget:changed", (e) => {
      const index = this.state.answers.findIndex((q) => q.id === e.detail.id);
      if (index === -1) {
        this.state.answers.push(e.detail);
      } else {
        this.state.answers[index].selected = e.detail.selected;
      }

      if (this.isEverythingAnswered) {
        this.querySelector(".btn-submit").removeAttribute("disabled");
      }

      this.moveOn = true;
    });
  }

  toggleSubmitButton() {
    if (this.isEverythingAnswered) {
      this.querySelector(".btn-submit").setAttribute("disabled", true);
    }
  }

  onSubmit(e) {
    e.target.setAttribute("disabled", true);
    post(this.submit)
      .then(() => {
        this.renderEnd();
      })
      .catch((r) => console.log(r));
  }

  display() {
    if (this.empty) return;

    const question = this.state.questions[this.state.index];
    const widget = new WIDGETS[question.widget]();

    const cachedAnswer = this.getCachedAnswer(question.id);
    widget.setAttribute("endpoint", this.endpoint);
    widget.setState({
      id: (cachedAnswer && cachedAnswer.id) || 0,
      selected: (cachedAnswer && cachedAnswer.selected) || [],
      questionId: question.id,
      inquiryId: question.inquiryId,
      label: question.label,
      options: question.options,
      readonly: this.isReadOnly,
      cache: cachedAnswer,
    });

    return widget;
  }

  getCachedAnswer(questionId) {
    return this.state.answers.find(
      (answer) => answer.questionId === questionId
    );
  }

  next() {
    if (!this.moveOn) return;
    if (this.state.index === this.state.questions.length - 1) return;

    const value = (this.state.index + 1) % this.state.questions.length;
    this.setState({ index: value });
  }

  previous() {
    if (!this.moveOn) return;
    if (this.state.index === 0) return;

    this.setState({ index: this.state.index - 1 });
  }

  render() {
    this.html`
      <div class="w-full">
        <div class="float-left cursor-pointer" onclick=${this} data-call="previous">
          <img class="h-10" src="/images/left-arrow.png">
        </div>
        <div class="float-right cursor-pointer" onclick=${this} data-call="next">
          <img class="h-10" src="/images/right-arrow.png">
        </div>
      </div>

      <div>
        ${this.display()}
      </div>
      <div class="text-center mt-8">
        <hr>
        <button class="btn btn-primary btn-submit mt-8 answer-button"
          disabled=${!this.isEverythingAnswered}
          onclick=${this}
          data-call="onSubmit">Submit</button>
      </div>
    `;
  }

  renderEnd() {
    this.html`
      <div class="text-center">
        <h1>Done, thank you for answering!</h1>
        <p>Well done! <a class="link" href="/s/profile">Now, click here to checkout your profile</a></p>
      </div>

    `;
  }
}

InquiryAnswerBuilder.define("ni-inquiry-answers");
export { InquiryAnswerBuilder };
