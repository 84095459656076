import HyperHTMLElement from "hyperhtml-element";
import { append, remove } from "lib/collections";

class Dropzone extends HyperHTMLElement {
  constructor() {
    super();
    this.widgets = [];
  }

  addWidget(widget) {
    this.widgets = append(this.widgets, widget);
    this.render();
  }

  removeWidget(widget) {
    this.widgets = remove(this.widgets, widget);
    this.render();
  }

  render() {
    return this.html`
      ${this.widgets.map((widget) => widget)}
    `;
  }
}

Dropzone.define("ni-dropzone");

export default { Dropzone };
