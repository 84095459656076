const HEADERS = {
  headers: {
    "X-CSRF-TOKEN": getToken(),
    "Content-Type": "application/json; charset=utf-8",
  },
};

function get(endpoint, options = {}) {
  return request(endpoint, "GET", null, options);
}

function post(endpoint, payload, options = {}) {
  return request(endpoint, "POST", payload, options);
}

function put(endpoint, payload, options = {}) {
  return request(endpoint, "PUT", payload, options);
}

function del(endpoint, options = {}) {
  return request(endpoint, "DELETE", null, options);
}

function request(endpoint, method, payload, options) {
  const methods = ["POST", "PUT"];
  const defaults = { method: method };
  let opts = {};
  opts = Object.assign(opts, defaults, options, HEADERS);

  if (methods.includes(method)) {
    if (payload instanceof FormData) {
      delete opts.headers["Content-Type"];
      opts.body = payload;
    } else {
      opts.headers["Content-Type"] = "application/json; charset=utf-8";
      opts.body = JSON.stringify(payload);
    }
  }

  return fetch(endpoint, opts).then(handle);
}

function handle(response) {
  if (response.ok) {
    return response.json().then((result) => result);
  }

  const error = {
    ok: response.ok,
    status: response.status,
    message: response.statusText,
  };

  return Promise.reject(error);
}

function getToken() {
  const el = document.querySelector('meta[name="csrf-token"]');
  if (!el) return false;

  return el.getAttribute("content");
}

export { del, get, post, put };
