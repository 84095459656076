import HyperHTMLElement from "hyperhtml-element";

class RemoteInput extends HyperHTMLElement {
  render() {
    this.html`
      <input type="text" class="form-input">
    `;
  }
}

RemoteInput.define("ni-remote-input");
