import HyperHTMLElement from "hyperhtml-element";
import QuestionWidget from "components/form-builder/question-widget";
import "components/data-combo";

const CHOICES = [
  { weight: 0, label: "Very Little" },
  { weight: 1, label: "Little" },
  { weight: 2, label: "Very Much" },
  { weight: 3, label: "Extremely" },
  { weight: 4, label: "I'm not Sure" },
];

const wire = HyperHTMLElement.wire;

class SingleChoiceInquiry extends QuestionWidget {
  get defaultState() {
    return {
      id: 0,
      vm: 1,
      vp: 1,
      label: "",
      options: CHOICES,
      widget: "ni-single-choice-inquiry",
    };
  }

  render() {
    this.html`
      <div class="border rounder p-2 mb-2">
        <button
          class="float-right"
          onclick="${this}"
          data-call="onClose">
          <i class="fas fa-times"></i>
        </button>
        <div class="form-group">
          <label for="question" class="form-label block">Inquiry Template</label>
          <ni-data-combo
            name="vm"
            class="inline-block mb-2"
            data=${{ payload: window.guida.vocationalMappings }}
            key="id"
            value="value"
            placeholder="Select Vocational Mapping"
            selected="${this.state.vm}"
            onchange=${this}
            data-call="onFormChange"></ni-data-combo>
          <ni-data-combo
            name="vp"
            data=${{ payload: window.guida.vocationalProfiles }}
            key="id"
            value="value"
            placeholder="Select Vocational Profile"
            selected="${this.state.vp}"
            onchange="${this}"
            data-call="onFormChange"></ni-data-combo>
          <input
            oninput="${this}"
            data-call="onFormChange"
            value="${this.state.label}"
            type="text"
            name="label"
            class="form-input mb-2"
            placeholder="Type your question"
            autofocus>
        </div>
        <div class="form-group">
          ${CHOICES.map((option) => this.radioButton(option))}
        </div>
      </div>
    `;
  }

  radioButton(option) {
    return wire()`
      <span class="inline-block pt-2 pb-2 pr-3 pl-3 bg-gray-300 rounded mr-2">
        ${option.label}
      </span>
    `;
  }
}

SingleChoiceInquiry.define("ni-single-choice-inquiry");
export { SingleChoiceInquiry };
