import HyperHTMLElement from "hyperhtml-element";
import { FreeAnswer } from "components/form-builder/free-answer";
import { SingleChoice } from "components/form-builder/single-choice-new";
import { MultipleChoice } from "components/form-builder/multiple-choice-new";
import { SingleChoiceInquiry } from "components/form-builder/single-choice-inquiry";
import { Quiz } from "components/form-builder/quiz";

import "components/form-builder/dropzone";
import { get } from "lib/requests";

class Index extends HyperHTMLElement {
  static get observedAttributes() {
    return ["endpoint", "questions"];
  }

  created() {
    this.addEventListener("ni-dropdown:click", this.onDropdownClick);
    this.addEventListener("ni-widget:click", this.onWidgetClose);
    this.dropzone = this.querySelector("ni-dropzone");

    get("/api/vocational-mappings")
      .then((result) => {
        window.guida.vocationalMappings = result;
      })
      .catch(() => (window.guida.vocationalMappings = []));

    get("/api/vocational-profiles")
      .then((result) => {
        window.guida.vocationalProfiles = result;
      })
      .catch(() => (window.guida.vocationalProfiles = []));

    if (this.questions) {
      get(this.questions)
        .then((result) => this.handleQuestions(result))
        .catch((error) => console.log(error));
    }
  }

  handleQuestions(questions) {
    questions.forEach((question) => this.handleQuestion(question));
  }

  handleQuestion(question) {
    let widget = null;

    switch (question.widget) {
      case "ni-single-choice-inquiry":
        widget = new SingleChoiceInquiry();
        break;
      case "ni-free-answer":
        widget = new FreeAnswer();
        break;
      case "ni-single-choice":
        widget = new SingleChoice();
        break;
      case "ni-multiple-choice":
        widget = new MultipleChoice();
        break;
      case "ni-quiz":
        widget = new Quiz();
        break;
    }

    if (widget === null) return;

    widget.setState({
      id: question.id,
      vm: question.vocationalMappingId,
      vp: question.vocationalProfileId,
      label: question.label,
      options: question.options,
    });

    widget.setAttribute("endpoint", this.endpoint);
    this.dropzone.addWidget(widget);
  }

  onDropdownClick(e) {
    let widget = null;

    switch (e.detail.payload) {
      case "free-answer":
        widget = new FreeAnswer();
        break;
      case "single-choice":
        widget = new SingleChoice();
        break;
      case "multiple-choice":
        widget = new MultipleChoice();
        break;
      case "single-choice-inquiry":
        widget = new SingleChoiceInquiry();
        break;
      case "quiz":
        widget = new Quiz();
        break;
    }

    if (widget === null) return;

    widget.setAttribute("endpoint", this.endpoint);
    this.dropzone.addWidget(widget);
  }

  onWidgetClose(e) {
    this.dropzone.removeWidget(e.detail);
  }

  disconnectedCallback() {
    this.removeEventListener("ni-dropdown:click", this.onDropdownClick);
    this.removeEventListener("ni-widget:click", this.onWidgetClose);
  }
}

Index.define("ni-form-builder");

export { Index };
