import HyperHTMLElement from "hyperhtml-element";
import "components/quest-builder/activity-widget";
import "components/autocomplete";

class Index extends HyperHTMLElement {
  static get observedAttributes() {
    return ["index", "store", "search"];
  }

  render() {
    this.html`
      <h2>Activities</h2>
      <ni-quest-activity
        trigger="ni-autocomplete-selected"
        quest="${this.index}"
        storeEndpoint="${this.store}"
        searchEndpoint="${this.search}">
    `;
  }
}

Index.define("ni-quest-builder");
export { Index };
