import HyperHTMLElement from "hyperhtml-element";

const wire = HyperHTMLElement.wire;

class DataCombo extends HyperHTMLElement {
  get key() {
    return this.getAttribute("key");
  }

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
  }

  get placeholder() {
    return this.getAttribute("placeholder");
  }

  get name() {
    return this.getAttribute("name");
  }

  render() {
    this.html`
      <select name="${this.name}">
        <option>${this.placeholder}</option>
        ${this.data.payload.map(
          (item) =>
            wire()`
              <option
                value="${item[this.key]}"
                selected="${item[this.key] === this.selected}">
                ${item[this.getAttribute("value")]}
              </option>
            `
        )}
      </select>
    `;
  }
}

DataCombo.define("ni-data-combo");
