import HyperHTMLElement from "hyperhtml-element";
import { append, removeAt } from "lib/collections";
import { post, get, del } from "lib/requests";

const wire = HyperHTMLElement.wire;

class QuestActivityWidget extends HyperHTMLElement {
  static get observedAttributes() {
    return ["trigger", "quest", "storeEndpoint", "searchEndpoint"];
  }

  get defaultState() {
    return {
      entries: [],
    };
  }

  created() {
    if (this.trigger) {
      this.addEventListener(this.trigger, this.onAdd);
    }

    get(this.storeEndpoint).then((result) =>
      this.setState({ entries: result })
    );
    this.render();
  }

  onAdd(e) {
    if (this.state.entries.some((entry) => entry.articleId === e.detail.id)) {
      return;
    }
    this.store(e.detail);
  }

  onRemove(e) {
    const el = e.target.closest("li");
    const index = parseInt(el.dataset.index, 10);
    const task = this.state.entries[index];
    del(`${this.storeEndpoint}/${task.id}`).then(
      this.setState({ entries: removeAt(this.state.entries, index) })
    );
  }

  store(payload) {
    post(this.storeEndpoint, payload).then((result) => {
      this.setState({ entries: append(this.state.entries, result) });
    });
  }

  disconnectedCallback() {
    if (this.trigger) {
      this.removeEventListener(this.trigger, this.onAdd);
    }
  }

  render() {
    this.html`
      <label class="form-label block mt-4" for="article-search">Task Search</label>
      <ni-auto-complete
        id="article-search"
        endpoint="${this.searchEndpoint}"
        trigger="trigger"
        placeholder="input task (article, inquiry or profession) name" />
      <ul class="mt-3">
        ${this.state.entries.map(
          (entry, index) => wire(entry, ":id")`
          <li
            class="border p-2 rounded mt-1 flex"
            data-index="${index}">
            <span class="flex-1">
            ${entry.title}<br>
            <span class="text-sm">${entry.activityType}</span>
            </span>
            <button
              class="self-center text-right inline-block"
              onClick=${this}
              data-call="onRemove">
              <i class="fas fa-times"></i>
            </button>
          </li>
        `
        )}
      </ul>
    `;
  }
}

QuestActivityWidget.define("ni-quest-activity");
export { QuestActivityWidget };
