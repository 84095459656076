import { wire } from "hyperhtml/esm";
import { SingleChoice } from "components/form-builder/single-choice-new";
import { append } from "lib/collections";

class Quiz extends SingleChoice {
  get defaultState() {
    return {
      id: 0,
      vm: 1,
      vp: 1,
      label: "",
      options: [],
      widget: "ni-quiz",
      selected: false,
    };
  }

  get title() {
    return "Quiz";
  }

  onOptionSelect(e) {
    const el = e.target;

    if (el.nodeName !== "SPAN") {
      return;
    }

    const index = parseInt(el.dataset.index, 10);
    const options = this.state.options.map((option) => {
      if (option.weight === index) {
        option.selected = true;
      } else {
        option.selected = false;
      }

      return option;
    });

    this.setState({ options: options });
    this.debounce();
  }

  onOptionInput(e) {
    if (e.which === 13) {
      const value = {
        weight: this.state.options.length,
        label: e.target.value,
        selected: false,
      };

      this.setState({ options: append(this.state.options, value) });
      e.target.value = "";
      this.debounce();
    }
  }

  render() {
    this.html`
      <div class="border rounder p-2 mb-2">
        <button
          class="float-right"
          onclick="${this}"
          data-call="onClose">
          <i class="fas fa-times"></i>
        </button>
        <div class="form-group">
          <label for="question" class="form-label block">${this.title}</label>
          <ni-data-combo
            name="vm"
            class="inline-block mb-2"
            data=${{ payload: window.guida.vocationalMappings }}
            key="id"
            value="value"
            placeholder="Select Vocational Mapping"
            selected="${this.state.vm}"
            onchange=${this}
            data-call="onFormChange"></ni-data-combo>
          <ni-data-combo
            name="vp"
            data=${{ payload: window.guida.vocationalProfiles }}
            key="id"
            value="value"
            placeholder="Select Vocational Profile"
            selected="${this.state.vp}"
            onchange="${this}"
            data-call="onFormChange"></ni-data-combo>
          <input
            oninput="${this}"
            data-call="onFormChange"
            value="${this.state.label}"
            type="text"
            name="label"
            class="form-input mb-2"
            placeholder="Type your question"
            autofocus>
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-input"
            placeholder="Input your option and press enter"
            onkeydown="${this}"
            data-call="onOptionInput">
        </div>
        <div class="form-group" onclick="${this}" data-call="onOptionClose">
          ${this.state.options.map((option) => this.radioButton(option))}
        </div>
      </div>
    `;
  }

  radioButton(option) {
    return wire()`
      <span
        class="cursor-pointer inline-block pt-2 pb-2 pr-3 pl-3 bg-gray-300 rounded mr-2 ${
          option.selected ? "ni-quiz-selected" : ""
        }"
        onclick="${this}"
        data-call="onOptionSelect"
        data-index="${option.weight}">
        ${option.label}
        <button class="ml-1" data-index="${option.weight}">
          <i class="fas fa-times"></i>
        </button>
      </span>
    `;
  }
}

Quiz.define("ni-quiz");
export { Quiz };
