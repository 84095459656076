import QuestionWidget from "components/form-builder/question-widget";
import "components/data-combo";

class FreeAnswer extends QuestionWidget {
  get defaultState() {
    return {
      id: 0,
      vm: 1,
      vp: 1,
      label: "",
      options: {},
      widget: "ni-free-answer",
    };
  }

  // created() {
  //   this.render();
  //   this.querySelector("[autofocus]").focus();
  // }

  render() {
    this.html`
      <div class="border rounded p-2 mb-2">
        <button
          class="float-right"
          data-call="onClose"
          onclick="${this}">
          <i class="fas fa-times"></i>
        </button>
          <div class="form-group">
            <label for="question" class="form-label block">Free Answer</label>
            <ni-data-combo
              name="vm"
              class="inline-block mb-2"
              data=${{ payload: window.guida.vocationalMappings }}
              key="id"
              value="value"
              placeholder="Select Vocational Mapping"
              selected="${this.state.vm}"
              onchange=${this}
              data-call="onFormChange"></ni-data-combo>
            <ni-data-combo
              name="vp"
              data=${{ payload: window.guida.vocationalProfiles }}
              key="id"
              value="value"
              placeholder="Select Vocational Profile"
              selected="${this.state.vp}"
              onchange="${this}"
              data-call="onFormChange"></ni-data-combo>
            <input
              type="text"
              name="label"
              class="form-input"
              data-call="onFormChange"
              value="${this.state.label}"
              oninput=${this}
              autofocus>
          </div>
      </div>
    `;
  }
}

FreeAnswer.define("ni-free-answer");

export { FreeAnswer };
