import "css/app.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "components/dropdown-menu";
import "components/form-builder";
import "components/form-builder/remote-input";
import "components/inquiry-answers";
import "components/quest-builder";
import "components/editor";

import { del, put } from "lib/requests";
import { debounce } from "lodash";

window.guida = {};

const forms = document.querySelectorAll("form[data-debounce]");

if (forms.length > 0) {
  document.addEventListener("input", debounce(handleForm, 500));
}

const download = document.querySelector(".btn-export");

if (download) {
  download.addEventListener("click", (e) => {
    const el = e.target;
    e.preventDefault();
    el.setAttribute("disabled", true);
    el.textContent = "Exporting. Please wait, this might take a while.";

    fetch(el.getAttribute("href"), {
      method: "GET",
    })
      .then(async (response) => {
        const header = response.headers.get("content-disposition");
        const filename = header.split("filename=")[1].split(";")[0];
        return { filename: filename, blob: await response.blob() };
      })
      .then((response) => {
        const blob = new Blob([response.blob], {
          type: "application/vnd.ms-excel; charset=utf-8",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
        } else {
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = response.filename;
          link.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 250);
        }

        el.removeAttribute("disabled");
        el.textContent = "Download";
      });
  });
}

document.addEventListener("click", (e) => {
  const el = e.target;
  if ("toggle" in el.dataset) {
    const state = el.dataset.toggle === "on";
    const parent = el.closest("form");
    parent.querySelectorAll("[type=checkbox]").forEach((checkbox) => {
      checkbox.checked = state;
    });
    e.preventDefault();
  }

  if (el.classList.contains("fa-trash-alt")) {
    if (!window.confirm("Are you sure you want to delete this item?")) {
      e.preventDefault();
    }
  }

  el.dispatchEvent(new Event("input", { bubbles: true }));
});

function handleForm(e) {
  const form = e.target.form;
  const checkboxes = new Map();

  if (!form || !form.dataset.debounce) return;

  form.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
    const name = checkbox.dataset.name;
    const current = checkboxes.get(name) || [];
    checkbox.checked && current.push(checkbox.value);
    checkboxes.set(name, current);
  });

  const endpoint = form.getAttribute("action");
  const formData = new FormData(form);
  const payload = Object.fromEntries(formData);
  checkboxes.forEach((value, key) => (payload[key] = value));
  put(endpoint, payload);
}
